import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import PageSection from '~/sections/PageSection'
import { Page, Metadata } from '~/components/Layout'
import styled from '@emotion/styled'

const Blog = ({ data: { page } }) => {
  const { metadata } = page

  return (
    <Page>
      <Metadata
        title={metadata.title}
        description={metadata.description.description}
        image={metadata.image.file.url}
      />
      <H1>{metadata.title}</H1>
      {page.sections.map(section => (
        <PageSection key={section.id} section={section} />
      ))}
  </Page>
  )
}

export default Blog

export const query = graphql`
  query BlogPage($locale: String) {
    page: contentfulPage(slug: { eq: "blog" }, node_locale: { eq: $locale }) {
      ...PageFragment
    }
  }
`

Blog.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      metadata: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.shape({
          description: PropTypes.string
        }),
        image: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string
          })
        })
      }),
      name: PropTypes.string,
      sections: PropTypes.arrayOf(PropTypes.shape({}))
    })
  })
}

const H1 = styled.h1`
  &:not(:focus) {
    &:not(:active) {
      position: absolute !important;
      width: 1px;
      height: 1px;
      white-space: nowrap;
      font-size: 0;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px, 1px, 1px, 1px); /* IE6, IE7 */
      &:active,
      &:focus {
        position: static;
        height: auto;
        margin: 0;
        overflow: visible;
        clip: auto;
      }
      display: inline-block;
      padding: 0 !important;
      margin: 0 !important;
      border: 0 !important;
    }
  }
`